import React from 'react';

const Input = ({ onChange, name, value, label, className, readOnly = false }) => {
  return (
    <div className={`relative ${className}`}>
      <label htmlFor={name} className="leading-7 text-sm text-gray-600">
        {label}
      </label>
      <input
        value={value}
        type="text"
        onChange={e => onChange(e.target.value)}
        name={name}
        id={name}
        readOnly={readOnly}
        className={`${
          readOnly ? 'cursor-not-allowed ' : ''
        }w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out`}
      />
    </div>
  );
};

export default Input;
