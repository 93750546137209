const { default: code3ExIncludeScript } = require('./code_example_include_script');

const code2ex =
  `/* Make sure to include 
  ${code3ExIncludeScript} in your index.html file */\n` +
  'class VideoStream extends React.Component {\n' +
  '\tconstructor(props) {\n' +
  '        super(props);\n' +
  '\n' +
  '        this.state = {\n' +
  '            theVerseEmojiBar: undefined\n' +
  '        };\n' +
  '    }\n' +
  '\t\n' +
  '\tcomponentDidMount() {\n' +
  '\t\tthis.setState({\n' +
  '\t\t\t// Advisable to make sure window.TheVerseLib is not undefined or inaccessible\n' +
  '            theVerseEmojiBar: window.TheVerseLib.default.widgets.emojiBar.new(\n' +
  '\t\t\t\t\t{\n' +
  '\t\t\t\t\t\tselector: "#theverse-emoji-plugin-wrapper",\n' +
  '\t\t\t\t\t\tstreamUid: "<streamUid>",\n' +
  '\t\t\t\t\t\talign: "bottom-right" // Optional "bottom-right", "center-right". Default: "bottom-right"\n' +
  '\t\t\t\t\t}\n' +
  '\t\t\t\t)\n' +
  '            }, () => {\n' +
  '                this.state.theVerseEmojiBar.render();\n' +
  '            });\n' +
  '\t}\n' +
  '\t\n' +
  '\tcomponentWillUnmount() {\n' +
  '        return this.state.theVerseEmojiBar?.unmount();\n' +
  '    }\n' +
  '\t\n' +
  '\t// theverse-emoji-plugin-wrapper should have same height as the player and placed on top of it\n' +
  '\trender() {\n' +
  '        return (\n' +
  '\t\t\t<div className="player-wrapper">\n' +
  '\t\t\t\t<div style={{position: "absolute", width: "100%", height: "100%"}}>\n' +
  '\t\t\t\t\t<iframe width="100%" height="100%" src="https://www.youtube.com/embed/<YOUR_STREAM_HERE>" frameborder="0"\n' +
  '\t\t\t\t\t\tallow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"\n' +
  '\t\t\t\t\t\tallowfullscreen>\n' +
  '\t\t\t\t\t</iframe>\n' +
  '\t\t\t\t</div>\n' +
  '\t\t\t\t<div id="theverse-emoji-plugin-wrapper" style={{ position: "absolute", width: "100%", height: "100%" }}></div>\n' +
  '\t\t\t</div>\n' +
  '        );\n' +
  '    }\n' +
  '}';

export default code2ex;
