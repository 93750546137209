import React from 'react';

const Pause = (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
    <g fill="none" fillRule="evenodd" stroke="#FDFEFE" strokeWidth="2">
      <circle cx="20" cy="20" r="19" fill="#000" fillOpacity=".05" />
      <path strokeLinecap="round" d="M23.5 14L23.5 26M16.5 14L16.5 26" />
    </g>
  </svg>
);
export default Pause;
