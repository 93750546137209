import React from 'react';

const Play = (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" className="play-button">
    <g fill="none" fillRule="evenodd" strokeWidth="2">
      <path stroke="#FFF" d="M25.984 20L15 13.723v12.554L25.984 20z" />
      <circle cx="20" cy="20" r="19" fill="#000" fillOpacity=".05" stroke="#FDFEFE" />
    </g>
  </svg>
);
export default Play;
