import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { isMobileOnly } from 'react-device-detect';
import './streams.scss';
import * as streamHelper from '../../../firebase/streamHelper';

Modal.setAppElement('#root');

const Streams = ({ user }) => {
  const [streams, setStreams] = useState([]);
  const [copyLabels, setCopyLabels] = useState([]);
  const [disableModalOpen, setDisableModalOpen] = useState(false);
  const [currentDocId, setCurrentDocId] = useState(undefined);

  function getStreams(userUID) {
    streamHelper.getStreamsByUserUid(userUID).then(querySnapshot => {
      const streams = [];
      const copyLabels = [];
      querySnapshot.forEach(doc => {
        streams.push({ ...doc.data(), docId: doc.id });
        copyLabels.push('Copy embed code');
      });
      setCopyLabels(copyLabels);
      setStreams(streams);
    });
  }

  const disableStream = () => {
    streamHelper
      .updateStream(currentDocId, {
        disabled: true
      })
      .then(() => {
        setDisableModalOpen(false);
        setCurrentDocId(undefined);
        getStreams(user.uid);
      });
  };

  const reactive = stream => {
    streamHelper
      .updateStream(stream.docId, {
        disabled: false
      })
      .then(() => {
        setCurrentDocId(undefined);
        getStreams(user.uid);
      });
  };

  useEffect(() => {
    getStreams(user.uid);
  }, [user.uid]);

  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `
      .top-100 {top: 100%}
      .bottom-100 {bottom: 100%}
      .max-h-select {
          max-height: 300px;
      }
    `
        }}
      />
      <section className="text-gray-600 body-font">
        <div className="container px-5 sm:pt-6 md:pt-24 mx-auto">
          <div className="lg:w-full flex flex-col sm:flex-row sm:items-center items-center mx-auto">
            <h1 className="flex-grow sm:pr-16 text-2xl font-medium title-font text-gray-900">Your streams</h1>
            <Link
              to="/streams/create"
              className="flex-shrink-0 text-white bg-indigo-500 border-0 py-2 sm:py-0 md:py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg mt-10 sm:mt-0 sm:mb-0"
            >
              Create new
            </Link>
          </div>
        </div>
      </section>

      <section className="text-gray-600 body-font">
        <div className="container px-5 py-10 md:py-24 mx-auto">
          <div className="flex flex-wrap -m-4">
            {streams.map((stream, key) => {
              return (
                <div key={key} className="p-4 md:w-1/3 w-full">
                  <div className="bg-gray-100 rounded h-full p-4 shadow">
                    <div className="flex">
                      <div className="w-2/3">
                        <h1 className={`text-lg font-semibold ${stream.disabled ? 'line-through' : ''}`}>
                          {stream.name}
                        </h1>
                        <Link to={`/streams/${stream.docId}/edit`} className="block text-xs uppercase text-blue-400">
                          Edit
                        </Link>
                      </div>
                      <div className="w-1/3">
                        {stream.disabled && (
                          <button
                            onClick={() => reactive(stream)}
                            className="float-right text-xs bg-red-600 rounded px-2 py-1 text-white"
                          >
                            Disabled
                          </button>
                        )}

                        {!stream.disabled && (
                          <button
                            onClick={() => {
                              setDisableModalOpen(true);
                              setCurrentDocId(stream.docId);
                            }}
                            className="float-right text-xs bg-indigo-500 rounded px-2 py-1 text-white"
                          >
                            Enabled
                          </button>
                        )}
                      </div>
                    </div>
                    <div className="py-4 text-sm flex-grow">
                      <Link to={`/dashboard/${stream.docId}`} className="mt-3 text-indigo-500 flex">
                        Dashboard
                        <svg
                          fill="none"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          className="w-4 h-4 ml-2"
                          viewBox="0 0 24 24"
                        >
                          <path d="M5 12h14M12 5l7 7-7 7" />
                        </svg>
                      </Link>
                      <Link
                        to={`/stream/${stream.docId}`}
                        className={`mt-3 text-indigo-500 flex ${
                          stream.disabled ? 'pointer-events-none opacity-50' : ''
                        }`}
                      >
                        Stream
                        <svg
                          fill="none"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          className="w-4 h-4 ml-2"
                          viewBox="0 0 24 24"
                        >
                          <path d="M5 12h14M12 5l7 7-7 7" />
                        </svg>
                      </Link>

                      {!isMobileOnly && (
                        <Link
                          to={`/stream-webcam/${stream.docId}`}
                          className={`mt-3 text-indigo-500 flex ${
                            stream.disabled ? 'pointer-events-none opacity-50' : ''
                          }`}
                        >
                          Stream with webcam
                          <svg
                            fill="none"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            className="w-4 h-4 ml-2"
                            viewBox="0 0 24 24"
                          >
                            <path d="M5 12h14M12 5l7 7-7 7" />
                          </svg>
                        </Link>
                      )}

                      <CopyToClipboard
                        text={`<iframe src="${window.location.origin}/stream/${stream.docId}" height="600" width="800" title="Iframe Example" ></iframe>`}
                        onCopy={() => {
                          const tempCopyLabels = [...copyLabels];
                          tempCopyLabels[key] = 'Copied';
                          setCopyLabels(tempCopyLabels);
                          setTimeout(() => {
                            const tempCopyLabels = [...copyLabels];
                            tempCopyLabels[key] = 'Copy embed code';
                            setCopyLabels(tempCopyLabels);
                          }, 2500);
                        }}
                      >
                        <button
                          className={`mt-3 text-indigo-500 flex focus:outline-none ${
                            stream.disabled ? 'pointer-events-none opacity-50' : ''
                          }`}
                          type="button"
                          disabled={stream.disabled}
                        >
                          {copyLabels[key]}
                        </button>
                      </CopyToClipboard>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <Modal
        isOpen={disableModalOpen}
        style={{
          overlay: {
            zIndex: '111',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          },
          content: {
            padding: 0,
            inset: 'auto'
          }
        }}
      >
        <div className="lg:flex-grow p-4 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
          <h2 className="title-font sm:text-3xl text-3xl mb-4 font-medium text-gray-900">Disable stream?</h2>

          <div className="flex justify-center w-full">
            <button
              onClick={disableStream}
              className="inline-flex text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg"
            >
              Disable
            </button>
            <button
              onClick={() => setDisableModalOpen(false)}
              className="ml-4 inline-flex text-gray-700 bg-gray-100 border-0 py-2 px-6 focus:outline-none hover:bg-gray-200 rounded text-lg"
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Streams;
