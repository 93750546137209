import React from 'react';
import './SoundSlider.scss';
import Slider from 'rc-slider';
import IconButton from '../icon-button';

const SoundSlider = React.memo(({ volume, onVolumeChange, onClick }) => {
  let iconState = 'soundOn';
  if (volume === 0) {
    iconState = 'soundOff';
  } else if (volume > 0 && volume <= 50) {
    iconState = 'soundHalf';
  }

  return (
    <div className="sound-slider">
      <IconButton Icon={iconState} className="muted-btn" onClick={onClick} />
      <Slider vertical value={volume} onChange={onVolumeChange} />
    </div>
  );
});

export default SoundSlider;
