import React from 'react';
import ReactPlayer from 'react-player';
import './stream.scss';
import Webcam from 'react-webcam';
import { withRouter } from 'react-router-dom';
// import queryString from "query-string";
import * as FaceApi from 'face-api.js';
// import Firebase from "../../firebase/setup";
import { createVideoUrl, getRandomNr } from './streamUtils';
import firebase from 'firebase/app';
import { detectAnimationEndEventName } from '../../utils/events/detectEventName';
import { emojiTypes } from '../../constants';
import Emoji from '../../components/EmotionBar/Emoji';
import Session from 'react-session-api';

const animationEndEventName = detectAnimationEndEventName();

const MODEL_DIRECTORY = '/models';
const DELAY_BETWEEN_RECOGNITION = 2;
const MINIMUM_SCORE = 0.7;
const VIDEO_SIZE = 224; // Should be divisible by 32
const VIDEO_HEIGHT = 168;

class WebcamStream extends React.Component {
  constructor(props) {
    super(props);
    this.webcamRef = React.createRef();

    this.state = {
      isLoaded: false,
      isPlaying: false,
      lastDetection: 0,
      detections: [],
      playerUrl: undefined,
      hasDoneFirstDetection: false,
      didUserAllowWebcam: true,
      isWebcamActive: false,
      videoUrl: undefined,
      newlyAddedView: [],
      currentClickedEmoji: {},
      origViews: [],
      floatingEmojis: [],
      otherPeoplesFloatingEmojis: [],
      views: [],
      bubbles: [],
      lastDetectedEmotion: undefined
    };

    this.onProgress = this.onProgress.bind(this);
    this.renderDebugBox = this.renderDebugBox.bind(this);
    this.detectEmotions = this.detectEmotions.bind(this);

    this.bubblesRefs = {};
    this.newAddedBubblesRefs = [];

    this.floatingEmojiCounter = 0;
    this.maxBubblesAmount = 10;
    this.currentSecond = 0;
    this.newlyAddedCounter = 0;
  }

  async componentDidMount() {
    await FaceApi.loadTinyFaceDetectorModel(MODEL_DIRECTORY);
    await FaceApi.loadFaceExpressionModel(MODEL_DIRECTORY);
    await FaceApi.loadAgeGenderModel(MODEL_DIRECTORY);

    firebase
      .firestore()
      .collection('streams')
      .doc(this.props.match.params.id)
      .get()
      .then(snapshot => {
        const { provider, url_id } = snapshot.data();
        const streams = [];

        firebase
          .firestore()
          .collection('streams')
          .doc(this.props.match.params.id)
          .collection('views')
          .orderBy('date')
          .get()
          .then(querySnapshot => {
            querySnapshot.forEach(doc => {
              streams.push({ ...doc.data() });
            });

            // let bubbles = {};
            // if (streams.length !== 0) {
            //     bubbles = this.formatBubbles(streams);
            // }

            this.setState(
              {
                origViews: streams,
                playerUrl: createVideoUrl(provider, url_id),
                isLoaded: true,
                isPlaying: true
              },
              () => {
                // Watch for new createdReactions
                this.addNewlyCreatedReactions();

                // if (streams.length !== 0) {
                //     // Show stored reactions
                //     if (bubbles[this.currentSecond]) {
                //         this.addOtherPeoplesEmojis(
                //             bubbles[this.currentSecond]
                //         );
                //     }
                //     this.currentSecond = this.currentSecond + 1;
                //     this.bubbleInterval = setInterval(() => {
                //         if (bubbles[this.currentSecond]) {
                //             this.addOtherPeoplesEmojis(
                //                 bubbles[this.currentSecond]
                //             );
                //             this.currentSecond =
                //                 this.currentSecond + 1;
                //         } else {
                //             clearInterval(this.bubbleInterval);
                //         }
                //     }, 1000);
                // }
              }
            );
          });
      });
  }

  setOthersBubblesRef = (progression, ref) => {
    if (ref) {
      if (this.bubblesRefs[progression]) {
        this.bubblesRefs[progression].push(ref);
      } else {
        this.bubblesRefs[progression] = [];
        this.bubblesRefs[progression].push(ref);
      }
    }
  };

  setNewlyAddedBubblesRef = ref => {
    if (ref) {
      this.newAddedBubblesRefs.push(ref);
    }
  };

  // formatBubbles = streams => {
  //     const bubbles = {};

  //     for (let index = 0; index < streams.length; index++) {
  //         const bubbleData = streams[index];
  //         const progression = bubbleData.seek;
  //         const emote = bubbleData.value;

  //         let color;
  //         if (emote === 0) {
  //             color = emojiTypes[0].color;
  //         } else if (emote === 1) {
  //             color = emojiTypes[1].color;
  //         } else {
  //             color = emojiTypes[2].color;
  //         }

  //         if (!bubbles[progression]) {
  //             bubbles[progression] = [];
  //             bubbles[progression].push(
  //                 this.createBubbles(index, progression, color, "small")
  //             );
  //         } else {
  //             if (bubbles[progression].length <= this.maxBubblesAmount - 1) {
  //                 bubbles[progression].push(
  //                     this.createBubbles(index, progression, color, "small")
  //                 );
  //             }
  //         }
  //     }

  //     return bubbles;
  // };

  // addOtherPeoplesEmojis = bubbles => {
  //     const currentSecond = this.currentSecond.valueOf();
  //     const { otherPeoplesFloatingEmojis } = this.state;
  //     const mergedOtherPeopleEmojis = [
  //         ...otherPeoplesFloatingEmojis,
  //         ...bubbles
  //     ];

  //     this.setState(
  //         { otherPeoplesFloatingEmojis: mergedOtherPeopleEmojis },
  //         () => {
  //             for (
  //                 let index = 0;
  //                 index < this.bubblesRefs[currentSecond].length;
  //                 index++
  //             ) {
  //                 const bubbleRef = this.bubblesRefs[currentSecond][index];
  //                 bubbleRef.addEventListener(
  //                     animationEndEventName,
  //                     e => {
  //                         const target = e.target;
  //                         const targetIndex = target.getAttribute("index");

  //                         const copyMergedOtherPeopleEmojis = [
  //                             ...this.state.otherPeoplesFloatingEmojis
  //                         ];

  //                         let correctIndex = undefined;

  //                         for (
  //                             let renderedIndex = 0;
  //                             renderedIndex <
  //                             copyMergedOtherPeopleEmojis.length;
  //                             renderedIndex++
  //                         ) {
  //                             const emoji =
  //                                 copyMergedOtherPeopleEmojis[renderedIndex];

  //                             if (emoji.key === targetIndex) {
  //                                 correctIndex = renderedIndex;
  //                             }
  //                         }

  //                         copyMergedOtherPeopleEmojis.splice(correctIndex, 1);
  //                         this.setState({
  //                             otherPeoplesFloatingEmojis: copyMergedOtherPeopleEmojis
  //                         });
  //                     },
  //                     { once: true }
  //                 );
  //             }
  //         }
  //     );
  // };

  addNewlyCreatedReactions = () => {
    firebase
      .firestore()
      .collection('streams')
      .doc(this.props.match.params.id)
      .collection('views')
      .orderBy('date')
      .onSnapshot(
        querySnapshot => {
          const views = [];
          querySnapshot.forEach(doc => {
            views.push({
              ...doc.data()
            });
          });

          if (this.state.origViews.length !== views.length) {
            const origLength = this.state.origViews.length.valueOf();

            const newlyAdded = [];
            const updatedState = {
              origViews: views
            };

            for (let index = origLength; index < views.length; index++) {
              const newView = views[index];

              if (
                this.state.currentClickedEmoji.date !== newView.date &&
                this.state.currentClickedEmoji.uid !== newView.uid
              ) {
                let color;
                if (newView.value === 0) {
                  color = emojiTypes[0].color;
                } else if (newView.value === 1) {
                  color = emojiTypes[1].color;
                } else {
                  color = emojiTypes[2].color;
                }

                newlyAdded.push(this.createBubbles(this.newlyAddedCounter, newView.seek, color, 'large'));

                this.newlyAddedCounter = this.newlyAddedCounter + 1;
              }
            }

            if (newlyAdded.length !== 0) {
              updatedState.newlyAddedView = [...this.state.newlyAddedView, ...newlyAdded];
            }

            this.setState(updatedState, () => {
              for (let newlyAddedIndex = 0; newlyAddedIndex < this.state.newlyAddedView.length; newlyAddedIndex++) {
                const element = this.state.newlyAddedView[newlyAddedIndex];

                const elementKey = element.key;

                const newAddedRef = this.newAddedBubblesRefs.filter(
                  bubble => bubble.getAttribute('index') === elementKey
                );

                if (newAddedRef.length !== 0) {
                  newAddedRef[0].addEventListener(
                    animationEndEventName,
                    () => {
                      const copyMergedNewlyAddedEmojis = [...this.state.newlyAddedView];
                      const correctEmoji = copyMergedNewlyAddedEmojis.filter(emoji => emoji.key === elementKey);
                      const indexOfCorrectEmoji = copyMergedNewlyAddedEmojis.indexOf(correctEmoji[0]);

                      if (indexOfCorrectEmoji !== -1) {
                        copyMergedNewlyAddedEmojis.splice(indexOfCorrectEmoji, 1);
                        this.setState({
                          newlyAddedView: copyMergedNewlyAddedEmojis
                        });
                      }
                    },
                    { once: true }
                  );
                }
              }
            });
          }
        },
        () => {
          console.log('got an error');
        }
      );
  };

  createBubbles = (index, progression, color, size) => {
    const props = {
      key: index,
      index,
      className: `bubble x${getRandomNr()}`,
      style: {
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        backgroundColor: color,
        height: size === 'small' ? '10px' : '30px',
        width: size === 'small' ? '10px' : '30px'
      }
    };

    if (size === 'large') {
      props.ref = ref => this.setNewlyAddedBubblesRef(ref);
    } else {
      props.ref = ref => this.setOthersBubblesRef(progression, ref);
    }

    return <div {...props} />;
  };

  async onProgress(state) {
    const played = Math.floor(state.playedSeconds);
    const shouldRecord = played % DELAY_BETWEEN_RECOGNITION === 0;

    if (!shouldRecord) {
      return;
    }

    await this.detectEmotions(played);
  }

  getCanvas() {
    const webcamRef = this.webcamRef;

    if (!webcamRef) {
      return null;
    }

    const instance = webcamRef.current;

    const canvas = instance.getCanvas();

    if (!canvas) {
      return null;
    }

    return canvas;
  }

  onFloatingAnimationDone = () => {
    const { floatingEmojis } = this.state;
    if (floatingEmojis.length !== 0) {
      floatingEmojis.shift();
      this.setState({
        floatingEmojis
      });
    }
  };

  async detectEmotions(timestamp) {
    const canvas = this.getCanvas();

    if (!canvas) {
      return;
    }

    const detections = await FaceApi.detectAllFaces(
      canvas,
      new FaceApi.TinyFaceDetectorOptions({
        inputSize: VIDEO_SIZE,
        scoreThreshold: MINIMUM_SCORE
      })
    )
      .withFaceExpressions()
      .withAgeAndGender();

    const mappedDetections = detections.map(detection => {
      const highestExpression = detection.expressions.asSortedArray()[0];
      return {
        age: detection.age,
        gender: detection.gender,
        genderProbability: detection.genderProbability,
        emotion: highestExpression.expression,
        emotionProbability: highestExpression.probability
      };
    });

    this.setState({
      detections: mappedDetections,
      hasDoneFirstDetection: true,
      lastDetection: timestamp
    });

    if (mappedDetections.length === 0) {
      return;
    }

    mappedDetections.forEach(detection => {
      if (this.state.lastDetectedEmotion !== detection.emotion) {
        console.log('!!!new reaction!!! ', detection.emotion);
        let emotionIndex = undefined;

        if (detection.emotion === 'neutral') {
          emotionIndex = 2;
        } else if (detection.emotion === 'happy') {
          emotionIndex = 4;
        } else if (detection.emotion === 'angry') {
          emotionIndex = 6;
        } else if (detection.emotion === 'sad') {
          emotionIndex = 3;
        } else {
          emotionIndex = 2;
        }

        const data = {
          source: 'Webcam',
          detectionValue: detection.emotion,
          value: emotionIndex,
          date: Date.now(),
          uid: Session.get('username'),
          ip: ''
        };

        const currentTime = Math.floor(this.player.getCurrentTime());

        data.seek = currentTime;

        const { floatingEmojis } = this.state;

        const mergedEmojis = floatingEmojis;

        mergedEmojis.push(
          <Emoji
            key={this.floatingEmojiCounter}
            onAnimationDone={() => this.onFloatingAnimationDone(this.floatingEmojiCounter)}
            type={emojiTypes[emotionIndex].value}
            state="flow"
          />
        );

        this.setState({
          currentClickedEmoji: data,
          floatingEmojis: mergedEmojis,
          lastDetectedEmotion: detection.emotion
        });

        this.floatingEmojiCounter = this.floatingEmojiCounter + 1;

        firebase
          .firestore()
          .collection('streams')
          .doc(this.props.match.params.id)
          .collection('views')
          .add(data);
      }
    });
  }

  renderDebugBox() {
    const { detections, isPlaying, hasDoneFirstDetection, lastDetection } = this.state;

    if (!isPlaying) {
      return null;
    }

    if (!this.props.debug) {
      return null;
    }

    if (!hasDoneFirstDetection) {
      return (
        <div className="debug-box">
          <p>Waiting for first detection...</p>
        </div>
      );
    }

    const countPersons = detections.length;

    if (countPersons === 0) {
      return (
        <div className="debug-box">
          <p>
            Cannot locate any persons in the webcam. Try to readjust the camera, the light resistance, and make sure you
            see yourself clearly.
          </p>
        </div>
      );
    }

    return (
      <div className="debug-box">
        <p>
          {countPersons} person{countPersons > 0 ? 's' : ''} in cam
        </p>
        <p>At {lastDetection} seconds</p>
        <hr />
        {detections.map((detection, index) => {
          return (
            <div className="cam-person" key={index}>
              <p>Person {index + 1}</p>
              <p>
                Emotion: {detection.emotion} ({detection.emotionProbability.toFixed(2)})
              </p>
              <p>
                Age/Gender: {Math.ceil(detection.age)} / {detection.gender} ({detection.genderProbability.toFixed(2)})
              </p>
            </div>
          );
        })}
      </div>
    );
  }

  render() {
    const {
      isLoaded,
      didUserAllowWebcam,
      isWebcamActive,
      floatingEmojis,
      playerUrl,
      isPlaying,
      otherPeoplesFloatingEmojis,
      newlyAddedView
    } = this.state;

    if (!isLoaded) {
      return <p>Loading</p>;
    }

    if (!didUserAllowWebcam) {
      return (
        <div className="error-box">
          <p>Please enable webcam when using Verse in mode</p>
        </div>
      );
    }

    return (
      <div className="stream flex justify-center">
        <ReactPlayer
          ref={ref => (this.player = ref)}
          url={playerUrl}
          playing={isPlaying}
          width="100vw"
          height="100vh"
          controls={true}
          onProgress={this.onProgress}
          onPlay={() => this.setState({ isPlaying: true })}
          onPause={() => this.setState({ isPlaying: false })}
        />
        <div className="floatingEmojiContainer floatingEmojiContainer--newAdded absolute">{newlyAddedView}</div>
        <div className="floatingEmojiContainer floatingEmojiContainer--others absolute">
          {otherPeoplesFloatingEmojis}
        </div>
        <div className="floatingEmojiContainer absolute">{floatingEmojis}</div>
        {this.renderDebugBox()}
        <div className={`webcam ${isWebcamActive ? 'webcam-activated' : ''}`}>
          <Webcam
            width={VIDEO_SIZE}
            height={VIDEO_HEIGHT}
            audio={false}
            className="webcam-video"
            onUserMediaError={() => this.setState({ didUserAllowWebcam: false })}
            ref={this.webcamRef}
          />
        </div>
        <div className="webcam-activator" onClick={() => this.setState({ isWebcamActive: !isWebcamActive })}>
          <img
            className={isWebcamActive ? 'closed' : 'open'}
            src={isWebcamActive ? '../images/close.png' : '../images/webcam.png'}
            alt="enable webcam"
          />
        </div>
      </div>
    );
  }
}

export default withRouter(WebcamStream);
