import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { register } from '../../firebase/authMethods';
import Error from '../../components/Error';

const Register = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);

  const createUser = async () => {
    if (email !== '' && password !== '') {
      register(email, password)
        .then(() => {
          console.log('user registret');
          // TODO: Create account record for meta
        })
        .catch(err => {
          setError(err.message);
        });
    }
  };

  return (
    <section className="text-gray-600 body-font relative">
      <div className="container px-5 py-24 mx-auto">
        <div className="flex flex-col text-center w-full mb-12">
          <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">Register user</h1>
          <p className="lg:w-2/3 mx-auto leading-relaxed text-base">Register and create streams</p>
        </div>
        <div className="lg:w-1/2 md:w-2/3 mx-auto">
          <div className="flex flex-wrap -m-2">
            <div className="p-2 w-1/2">
              <div className="relative">
                <label htmlFor="email" className="leading-7 text-sm text-gray-600">
                  Email
                </label>
                <input
                  type="text"
                  id="email"
                  name="email"
                  onChange={e => setEmail(e.target.value)}
                  placeholder="email"
                  value={email}
                  className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                />
              </div>
            </div>
            <div className="p-2 w-1/2">
              <div className="relative">
                <label htmlFor="password" className="leading-7 text-sm text-gray-600">
                  Password
                </label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  onChange={e => setPassword(e.target.value)}
                  placeholder="password"
                  value={password}
                  className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                />
              </div>
            </div>

            <div className="p-2 w-full">
              <Error message={error} />
            </div>

            <div className="p-2 w-full">
              <button
                type="button"
                onClick={createUser}
                className="flex mx-auto text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg"
              >
                Sign up
              </button>
            </div>
            <div className="p-2 w-full pt-8 mt-8 border-t border-gray-200 text-center">
              <Link to="/login" className="text-indigo-500">
                Login
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default withRouter(Register);
