import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { isMobileOnly } from 'react-device-detect';
import { firebaseAuth } from './firebase/setup';

import Dashboard from './pages/protected/Dashboard/Dashboard';
import Home from './pages/protected/Home/Home';
import Streams from './pages/protected/Streams/Streams';
import Account from './pages/protected/Account/Account';

import EmojiStream from './pages/Stream/Stream';
import WebcamStream from './pages/Stream/Webcam';
import EmbedPageDemo from './pages/EmbedPageDemo/EmbedPageDemo';
import PublicHome from './pages/PublicHome/PublicHome';
import Login from './pages/Login/Login';
import Logout from './pages/Login/Logout';
import Register from './pages/Register/Register';
import 'tailwindcss/tailwind.css';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import Password from './pages/Password/Password';
import CreateEditStream from './pages/protected/Streams/Crud/CreateEditStream';
import GuideForPartners from './pages/Guides/GuideForPartners';

import './styles/index.scss';
import './app.scss';

function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

const PrivateRoute = ({ component: Component, authed, user, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        authed === true ? (
          <Component {...props} user={user} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};

const PublicRoute = ({ component: Component, authed, ...rest }) => {
  return <Route {...rest} render={props => (authed === false ? <Component {...props} /> : <Redirect to="/" />)} />;
};

const App = () => {
  const [authed, setAuthed] = useState(false);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(undefined);
  const isIframe = inIframe();

  useEffect(() => {
    firebaseAuth().onAuthStateChanged(user => {
      if (user) {
        setUser(user);
        setAuthed(true);
        setLoading(false);
      } else {
        setAuthed(false);
        setLoading(false);
      }
    });
  }, []);

  return loading ? (
    <h1>Loading</h1>
  ) : (
    <main>
      <div id="page-container">
        <Router>
          {!isIframe ? <Header authed={authed} user={user} /> : null}
          <div id="content-wrap">
            <Switch>
              <Route path="/" exact>
                {authed ? (
                  <Redirect
                    to={{
                      pathname: '/streams'
                    }}
                  />
                ) : (
                  <PublicHome />
                )}
              </Route>

              <PublicRoute authed={authed} path="/login" component={Login} />

              <Route authed={authed} path="/logout" component={Logout} />

              <PublicRoute authed={authed} path="/reg" component={Register} />

              <Route authed={authed} path="/embed-page" component={EmbedPageDemo} />

              <Route authed={authed} path="/password" component={Password} />

              <PrivateRoute authed={authed} path="/home" component={Home} />

              <Route authed={authed} path="/dashboard/:id" component={Dashboard} />

              <Route authed={authed} path="/guide-for-partners" component={GuideForPartners} />

              <PrivateRoute authed={authed} user={user} path="/streams" component={Streams} exact />

              <PrivateRoute authed={authed} user={user} path="/streams/:id/edit" component={CreateEditStream} />

              <PrivateRoute authed={authed} user={user} path="/streams/create" component={CreateEditStream} />

              <PrivateRoute authed={authed} path="/account" component={Account} />

              <Route path="/stream/:id" component={EmojiStream} />

              {!isMobileOnly && <Route path="/stream-webcam/:id" component={WebcamStream} />}

              <Route render={() => <h3 style={{ backgroundColor: 'white' }}>No Match</h3>} />
            </Switch>
          </div>
          {!isIframe ? <Footer /> : null}
        </Router>
      </div>
    </main>
  );
};

export default App;
