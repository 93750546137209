export function createVideoUrl(provider, id) {
  return provider === 'youtube' ? `https://www.youtube.com/watch?v=${id}` : `https://vimeo.com/${id}`;
}

export function getRandomNr() {
  return Math.floor(Math.random() * 5) + 1;
}

export function randomIntFromInterval(min, max) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export const providers = [
  {
    name: 'YouTube',
    value: 'youtube'
  },
  {
    name: 'Vimeo',
    value: 'vimeo'
  }
];

export const emojiBackgrounds = [
  {
    name: 'default',
    value: ''
  },
  {
    name: 'yellow',
    value: 'yellow'
  },
  {
    name: 'blue',
    value: 'blue'
  }
];

export function youtube_parser(url) {
  const regExp = /^https?:\/\/(?:www\.youtube(?:-nocookie)?\.com\/|m\.youtube\.com\/|youtube\.com\/)?(?:ytscreeningroom\?vi?=|youtu\.be\/|vi?\/|user\/.+\/u\/\w{1,2}\/|embed\/|watch\?(?:.*&)?vi?=|&vi?=|\?(?:.*&)?vi?=)([^#\\&?\n/<>"']*)/i;
  const match = url.match(regExp);
  return match && match[1].length === 11 ? match[1] : false;
}
export function vimeo_parser(url) {
  const regExp = /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/;
  const match = url.match(regExp);
  return match ? match[5] : false;
}

export function getUrlId(source, videoUrl) {
  switch (source) {
    case 'youtube':
      return youtube_parser(videoUrl);
    case 'vimeo':
      return vimeo_parser(videoUrl);
    default:
      return false;
  }
}
