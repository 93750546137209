import React, { useEffect, useState } from 'react';
import './login.css';
import { Redirect } from 'react-router-dom';
import { logout as firebaseLogout } from '../../firebase/authMethods';

const Logout = props => {
  const [isLoggedOut, setIsLoggedOut] = useState(false);

  useEffect(() => {
    firebaseLogout().then(() => {
      setIsLoggedOut(true);
    });
  }, []);

  return isLoggedOut ? <Redirect to="/" /> : <span>Logout</span>;
};

export default Logout;
