import { firestore, firebaseStorage } from './setup';

const STREAMS_COLLECTION = 'streams';

export async function getStreamById(streamId) {
  return await firestore
    .collection(STREAMS_COLLECTION)
    .doc(streamId)
    .get();
}

export async function getStreamsByUserUid(userUid) {
  return await firestore
    .collection(STREAMS_COLLECTION)
    .where('user_uid', '==', userUid)
    .get();
}

export async function updateStream(streamId, stream) {
  return await firestore
    .collection(STREAMS_COLLECTION)
    .doc(streamId)
    .update(stream);
}

export async function addStream(stream) {
  return await firestore.collection('streams').add(stream);
}

export function uploadImage(id, image) {
  firebaseStorage
    .ref(`streams/${id}`)
    .put(image)
    .on(
      'state_changed',
      snapshot => {
        /* PROGRESS */
      },
      error => console.error('Error uploading image', error),
      () => {
        firebaseStorage
          .ref(STREAMS_COLLECTION)
          .child(id)
          .getDownloadURL()
          .then(url => {
            updateStream(id, { image_url: url });
          });
      }
    );
}
