import React from 'react';

const SoundOn = (
  <svg width="40" height="40" viewBox="0 0 40 40">
    <g fill="none" fillRule="evenodd">
      <circle cx="20" cy="20" r="20" fill="#000" fillOpacity=".3" />
      <circle cx="20" cy="20" r="19" stroke="#FFF" strokeWidth="2" />
      <path
        fill="#FFF"
        d="M12 17.5v5c0 .276.224.5.5.5H16l4.146 4.146c.196.196.512.196.708 0 .093-.093.146-.22.146-.353V13.207c0-.276-.224-.5-.5-.5-.133 0-.26.053-.354.147L16 17h-3.5c-.276 0-.5.224-.5.5z"
      />
      <g fill="#FFF" transform="translate(22.5 16)">
        <rect width="1.5" height="8" x=".5" rx=".75" />
        <rect width="1.5" height="4" x="4" y="2" rx=".75" />
      </g>
    </g>
  </svg>
);
export default SoundOn;
