import React, { useEffect, useMemo, useState } from 'react';
import { PieChart, Pie, Cell, XAxis, YAxis, CartesianGrid, Legend, AreaChart, Area, Tooltip } from 'recharts';
import CountUp from 'react-countup';
import { useParams } from 'react-router-dom';
import Firebase from '../../../firebase/setup';
import { emojiTypes } from '../../../constants';
import './dashboard.scss';
import { isMobileOnly } from 'react-device-detect';
// import EmojiStream from "../../Stream/Stream";

function formatTime(timestamp) {
  const date = new Date(timestamp * 1000);
  const hours = date.getHours() < 10 ? `0${date.getHours().toString()}` : date.getHours().toString();
  const minutes = date.getMinutes() < 10 ? `0${date.getMinutes().toString()}` : date.getMinutes().toString();
  const seconds = date.getSeconds() < 10 ? `0${date.getSeconds().toString()}` : date.getSeconds().toString();

  let day = date.getDate();
  let month = date.getMonth() + 1;
  const year = date.getFullYear();

  day = day < 10 ? `0${day}` : day;
  month = month < 10 ? `0${month}` : month;

  return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
}

// function formatVideoTime(totalSeconds) {
//     const date = new Date(null);
//     date.setSeconds(totalSeconds);
//     const hours = date.getHours();
//     const minutes = date.getMinutes();
//     const seconds = date.getSeconds();

//     return `${hours}:${minutes}:${seconds}`;
// }

function getEmojiName(emojiIndex) {
  return emojiTypes[emojiIndex].value;
}

const RADIAN = Math.PI / 180;

const renderCustomizedLabel = props => {
  const { cx, cy, midAngle, innerRadius, outerRadius, percent } = props;
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

function calculateTotal(data) {
  const formattedData = Object.entries(data);
  let total = 0;

  for (let index = 0; index < formattedData.length; index++) {
    const value = formattedData[index];
    total += value[1];
  }

  return total;
}

const Dashboard = () => {
  const [uniqueViewers, setUniqueViewers] = useState(0);
  const [totals, setTotals] = useState([]);
  const [countryTotals, setCountryTotals] = useState({});
  const [totalResponses, setTotalResponses] = useState(0);
  const [emotionPrTimestamp, setEmotionPrTimestamp] = useState([]);
  const [emotionPrDate, setEmotionPrDate] = useState([]);
  const [liveStream, setLiveStream] = useState(false);
  const [streamName, setStreamName] = useState('Name');

  const styleTag = useMemo(
    () => `
    :root {
      --country-numbers: ${Object.keys(countryTotals).length};
    }
    `,
    [countryTotals]
  );

  const { id } = useParams();

  Firebase.firestore()
    .doc(`streams/${id}`)
    .get()
    .then(
      doc => {
        const data = doc.data();
        setStreamName(data.name);
        setLiveStream(data.isLiveStream);
      },
      () => {
        console.log('stream got an error');
      }
    );

  useEffect(() => {
    Firebase.firestore()
      .doc(`streams/${id}/stats/viewers`)
      .onSnapshot(
        doc => {
          if (doc.data()) {
            setUniqueViewers(doc.data().unique);
          }
        },
        () => {
          console.log('viewers got an error');
        }
      );

    Firebase.firestore()
      .doc(`streams/${id}/stats/total`)
      .onSnapshot(
        doc => {
          console.log('NEW CALL');
          console.log(doc.data());
          if (doc.data()) {
            const emojiData = Object.entries(doc.data())
              .filter(([key, value]) => parseInt(key) != key) // filter old number based stats
              .reduce(
                (acc, [key, value]) => {
                  if (key === 'total') {
                    acc.total = value;
                  } else {
                    acc.countries[key] = value;
                  }
                  return acc;
                },
                { countries: {}, total: [] }
              );
            setCountryTotals(emojiData.countries);
            setTotals(
              Object.entries(emojiData.total).map(([key, value]) => ({
                name: key,
                value
              }))
            );
            setTotalResponses(calculateTotal(doc.data()));
          }
        },
        () => {
          console.log('stats got an error');
        }
      );

    Firebase.firestore()
      .collection(`streams/${id}/telemetry`)
      .onSnapshot(
        querySnapshot => {
          const datestamp = [];
          querySnapshot.forEach(doc => {
            const second = parseInt(doc.id.replace('s_', ''));
            const data = doc.data();
            datestamp.push({
              [getEmojiName(0)]: data[0] || 0,
              [getEmojiName(1)]: data[1] || 0,
              [getEmojiName(2)]: data[2] || 0,
              [getEmojiName(3)]: data[3] || 0,
              [getEmojiName(4)]: data[4] || 0,
              [getEmojiName(5)]: data[5] || 0,
              [getEmojiName(6)]: data[6] || 0,
              time: second,
              timestamp: formatTime(second),
              total: calculateTotal(doc.data())
            });
          });

          datestamp.sort((a, b) => (a.time > b.time ? 1 : -1));

          setEmotionPrDate(datestamp);
        },
        () => {
          console.log('telemetry got an error');
        }
      );

    Firebase.firestore()
      .collection(`streams/${id}/telemetry`)
      .onSnapshot(
        querySnapshot => {
          const timestamps = [];
          querySnapshot.forEach(doc => {
            const second = parseInt(doc.id.replace(/^\D+/g, ''));
            const data = doc.data();
            timestamps.push({
              [getEmojiName(0)]: data[0] || 0,
              [getEmojiName(1)]: data[1] || 0,
              [getEmojiName(2)]: data[2] || 0,
              [getEmojiName(3)]: data[3] || 0,
              [getEmojiName(4)]: data[4] || 0,
              [getEmojiName(5)]: data[5] || 0,
              [getEmojiName(6)]: data[6] || 0,
              timestamp: second,
              total: calculateTotal(doc.data())
            });
          });

          setEmotionPrTimestamp(timestamps.sort((a, b) => (a.timestamp > b.timestamp ? 1 : -1)));
        },
        () => {
          console.log('telemetry got an error');
        }
      );
  }, [id, liveStream]);

  return (
    <>
      <style dangerouslySetInnerHTML={{ __html: styleTag }} />
      <div className="dashboard m-5 container mx-auto">
        {totalResponses === 0 ? (
          <div className="-my-2 py-2 overflow-x-auto p-5">
            <h1>{streamName}</h1>
          </div>
        ) : (
          <div className="-my-2 py-2 overflow-x-auto p-5">
            <div className="-my-2 py-2 overflow-x-auto p-5">
              <h1>{streamName}</h1>
            </div>
            <div className="align-middle inline-block min-w-full overflow-hidden bg-white rounded-bl-lg rounded-br-lg">
              <div className="md:flex md:justify-around md:items-center">
                <PieChart width={isMobileOnly ? 300 : 400} height={isMobileOnly ? 300 : 400} className="mx-auto w-1/2">
                  <Pie
                    data={totals}
                    cx={isMobileOnly ? 150 : 200}
                    cy={isMobileOnly ? 150 : 200}
                    labelLine={false}
                    label={renderCustomizedLabel}
                    outerRadius={isMobileOnly ? 120 : 180}
                    fill="#8884d8"
                    dataKey="value"
                  >
                    {totals.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={emojiTypes[parseInt(entry.name)].color} />
                    ))}
                  </Pie>
                </PieChart>
                <div className="w-1/2 emoji-colors">
                  <div></div>
                  <div></div>
                  <div></div>
                  {Object.keys(countryTotals).map(country => (
                    <div>{country}</div>
                  ))}
                  {emojiTypes.map((emoji, key) => (
                    <React.Fragment key={key}>
                      <div
                        className="emoji-color flex items-center justify-center text-white"
                        style={{
                          backgroundColor: emoji.color
                        }}
                      >
                        {totals && totals.find(total => parseInt(total.name) === key)?.value}
                      </div>
                      <div className="emoji-name m-2 text-xs">{emoji.name}</div>
                      <img className="emoji-emo" src={emoji.emoji} alt={emoji.name} />
                      {Object.keys(countryTotals).map(country => (
                        <div>{countryTotals[country][key]}</div>
                      ))}
                    </React.Fragment>
                  ))}
                </div>
              </div>

              <div className="-my-2 py-2 overflow-x-auto p-5">
                <h1>Reactions</h1>
              </div>

              {liveStream ? (
                <div>
                  {' '}
                  <AreaChart
                    width={1200}
                    height={600}
                    data={emotionPrDate}
                    margin={{
                      bottom: 120
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="timestamp" angle="-75" textAnchor="end" />
                    <YAxis />
                    <Legend layout="horizontal" verticalAlign="top" align="center" margin={{ top: 10 }} />
                    <Tooltip />
                    {emojiTypes.map((type, key) => (
                      <Area
                        key={key}
                        name={type.name}
                        type="monotone"
                        dataKey={type.value}
                        stackId="1"
                        stroke={type.color}
                        fill={type.color}
                      />
                    ))}
                  </AreaChart>
                </div>
              ) : (
                <div>
                  {' '}
                  <AreaChart width={1200} height={400} data={emotionPrTimestamp}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="timestamp" />
                    <YAxis />
                    <Legend />
                    <Tooltip />
                    {emojiTypes.map((type, key) => (
                      <Area
                        key={key}
                        name={type.name}
                        type="monotone"
                        dataKey={type.value}
                        stackId="1"
                        stroke={type.color}
                        fill={type.color}
                      />
                    ))}
                  </AreaChart>
                </div>
              )}

              <section className="text-gray-600 body-font">
                <div className="container px-5 py-24 mx-auto">
                  <div className="flex flex-wrap -m-4 text-center">
                    <div className="p-4 sm:w-1/3">
                      <h2 className="title-font font-medium sm:text-4xl text-3xl text-gray-900">Reacting</h2>
                      <p className="leading-relaxed">
                        <CountUp preserveValue duration={1} start={0} end={uniqueViewers} />
                      </p>
                    </div>
                    <div className="p-4 sm:w-1/3">
                      <h2 className="title-font font-medium sm:text-4xl text-3xl text-gray-900">Reactions</h2>
                      <p className="leading-relaxed">
                        <CountUp preserveValue duration={1} start={0} end={totalResponses} />
                      </p>
                    </div>
                  </div>
                </div>
              </section>

              {/* <section className="text-gray-600 body-font">
                            <div className="container px-5 py-24 mx-auto">
                                <div className="flex flex-wrap -m-4 text-center">
                                    <EmojiStream
                                        streamUid={id}
                                        autoPlay={false}
                                        forceShowEmojisOnPlayback={true}
                                        isDashboard={true}
                                    ></EmojiStream>
                                </div>
                            </div>
                        </section> */}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Dashboard;
