import React from 'react';
import ReactPlayer from 'react-player';
import './stream.scss';
import './floatingEmojiContainer.scss';
import firebase from 'firebase/app';
import { createVideoUrl } from './streamUtils';
import ProgressBar from '../../components/progress-bar';
import IconButton from '../../components/icon-button';
import { Redirect } from 'react-router-dom';
import Session from 'react-session-api';
import SoundSlider from '../../components/Soundslider/SoundSlider';
import isSandbox from '../../firebase/environment';

class EmojiStream extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      playing: false,
      videoUrl: undefined,
      views: [],
      duration: 0,
      seeking: false,
      played: 0,
      isFullscreen: false,
      passwordProtected: false,
      volume: 0,
      disabled: false,
      isLiveStream: false,
      showEmojisOnPlayback: true,
      theVerseEmojiBar: undefined
    };
    this.streamUid =
      this.props && this.props.match && this.props.match.params && this.props.match.params.id
        ? this.props.match.params.id
        : this.props.streamUid;
    this.isDashboard = this.props && this.props.isDashboard;
    this.autoPlay = this.props && this.props.autoPlay !== undefined ? this.props.autoPlay : true;
    this.forceShowEmojisOnPlayback = this.props && this.props.forceShowEmojisOnPlayback;
    this.currentSecond = 0;
  }

  componentDidMount() {
    firebase
      .firestore()
      .doc(`streams/${this.streamUid}`)
      .get()
      .then(snapshot => {
        const { provider, url_id, password, disabled, isLiveStream, showEmojisOnPlayback } = snapshot.data();
        if (!disabled) {
          if (password === false || Session.get(`docId_${this.streamUid}`)) {
            this.setState(
              {
                videoUrl: createVideoUrl(provider, url_id),
                playing: this.autoPlay,
                isLiveStream,
                showEmojisOnPlayback
              },
              () => {
                try {
                  this.setState(
                    {
                      theVerseEmojiBar: window.TheVerseLib.default.widgets.emojiBar.new({
                        selector: '#theverse-emoji-plugin-wrapper',
                        streamUid: this.streamUid,
                        align: 'bottom-right',
                        sandbox: isSandbox(),
                        forceShowEmojisOnPlayback: this.forceShowEmojisOnPlayback
                      })
                    },
                    () => {
                      try {
                        this.state.theVerseEmojiBar.render();
                      } catch (e) {
                        console.error('Error rendering plugin', e);
                      }
                    }
                  );
                } catch (e) {
                  console.error('Error initializing plugin', e);
                }
              }
            );
          } else {
            this.setState({
              passwordProtected: password,
              isLiveStream
            });
          }
        } else {
          this.setState({
            disabled,
            isLiveStream
          });
        }
      });
  }

  componentWillUnmount() {
    if (this.state.theVerseEmojiBar) {
      this.state.theVerseEmojiBar.unmount();
    }
  }

  handleProgress = state => {
    this.currentSecond = Math.floor(state.playedSeconds);
    // Only want to update time slider if we are not currently seeking
    if (!this.state.seeking) {
      this.setState({
        played: state.played
      });
    }
  };

  handleDuration = duration => {
    this.setState({
      duration
    });
  };

  togglePlayState = () => {
    this.setState(
      {
        playing: !this.state.playing
      },
      () => {
        if (!this.state.playing && this.player && this.player.getInternalPlayer()) {
          // ReactPlayer has an issue where setting playing false might not be enough
          // https://github.com/CookPete/react-player/issues/749
          this.player.getInternalPlayer().pauseVideo();
        }
      }
    );
  };

  onSliderChange = value => {
    this.setState(
      {
        played: value / 100
      },
      () => {
        const currentTime = this.state.duration - (this.state.duration - this.state.duration * (value / 100));
        this.currentSecond = Math.floor(currentTime);
      }
    );
    this.player.seekTo(parseFloat(value / 100));
  };

  escapeClose = e => {
    if (e.keyCode === 27) {
      this.setState({
        isFullscreen: false
      });
      window.removeEventListener('keydown', this.escapeClose);
    }
  };

  setFullScreen = () => {
    this.setState(
      {
        isFullscreen: !this.state.isFullscreen
      },
      () => {
        if (this.state.isFullscreen) {
          let footer = document.getElementById('footer');
          if (footer) {
            footer.classList.add('hidden');
          }
          window.addEventListener('keydown', this.escapeClose);
        } else {
          let footer = document.getElementById('footer');
          if (footer) {
            footer.classList.remove('hidden');
          }
          window.removeEventListener('keydown', this.escapeClose);
        }
      }
    );
  };

  render() {
    const { videoUrl, playing, isFullscreen, passwordProtected, volume, disabled } = this.state;

    if (disabled) {
      return (
        <section className="text-gray-600 body-font">
          <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
            <div className="lg:flex-grow lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
              <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">Not available</h1>
            </div>
          </div>
        </section>
      );
    } else if (!this.isDashboard && passwordProtected && !Session.get(`docId_${this.streamUid}`)) {
      return (
        <Redirect
          to={{
            pathname: '/password',
            state: { docId: this.streamUid }
          }}
        />
      );
    } else {
      return (
        <div className="stream md:flex md:justify-end">
          <div className={`player-wrapper${isFullscreen ? ' player-wrapper--fullscreen' : ''}`}>
            <ReactPlayer
              ref={ref => (this.player = ref)}
              url={videoUrl}
              playing={playing}
              width="100%"
              height="100%"
              controls={false}
              playsinline={true}
              className="react-player"
              onProgress={this.handleProgress}
              onDuration={this.handleDuration}
              pip={false}
              volume={volume / 100}
              onPause={() => {
                this.setState(
                  {
                    playing: false
                  },
                  () => {
                    this.state.theVerseEmojiBar.videoStopped();
                  }
                );
              }}
              onPlay={() => {
                this.setState(
                  {
                    playing: true
                  },
                  () => {
                    this.state.theVerseEmojiBar.setVideoProgress(this.currentSecond);
                  }
                );
              }}
              config={{
                youtube: {
                  playerVars: {
                    modestbranding: 1,
                    rel: 0,
                    controls: 0,
                    fs: 0,
                    iv_load_policy: 3,
                    playsinline: 1
                  }
                }
              }}
            />

            <SoundSlider
              volume={volume}
              onVolumeChange={volume =>
                this.setState({
                  volume: volume
                })
              }
              onClick={() => {
                this.setState({
                  volume: volume !== 0 ? 0 : 50
                });
              }}
            />

            <IconButton Icon="fullscreen" className="slide-fullscreen-btn" onClick={this.setFullScreen} />

            <ProgressBar
              played={this.state.played}
              onSliderChange={this.onSliderChange}
              duration={this.state.duration}
              playing={this.state.playing}
              togglePlaystate={this.togglePlayState}
              onBeforeChange={this.onBeforeChange}
              onAfterChange={this.onAfterChange}
            />
          </div>

          <div
            id="theverse-emoji-plugin-wrapper"
            className={`emoji-plugin-wrapper${isFullscreen ? ' emoji-plugin-wrapper--fullscreen' : ''}`}
          />
        </div>
      );
    }
  }
}

export default EmojiStream;
