import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/auth';
import { verseConfig, verseSandboxConfig } from '../firebase-config';
import isSandbox from './environment';

const Firebase = firebase.initializeApp(isSandbox() ? verseSandboxConfig() : verseConfig());
Firebase.analytics();

export const firebaseAuth = firebase.auth;
export const firestore = Firebase.firestore();
export const firebaseStorage = Firebase.storage();

export default Firebase;
