import { firebaseAuth } from './setup';

export function register(email, pw) {
  return firebaseAuth()
    .createUserWithEmailAndPassword(email, pw)
    .then(() => sendVerificationEmail());
}

export function sendVerificationEmail() {
  return firebaseAuth().currentUser.sendEmailVerification();
}

export async function logout() {
  return firebaseAuth().signOut();
}

export function login(email, pw) {
  return firebaseAuth().signInWithEmailAndPassword(email, pw);
}

export function resetPassword(email) {
  return firebaseAuth().sendPasswordResetEmail(email);
}
