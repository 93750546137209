import React from 'react';

const Fullscreen = (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
    <g fill="none" fillRule="evenodd" stroke="#FFF" strokeWidth="2">
      <path d="M0 6.667L0 0 6.667 0" transform="translate(1 1)" />
      <g>
        <g>
          <path d="M0 6.667L0 0 6.667 0" transform="translate(1 1) rotate(-180 10 10)" />
          <path d="M13.333 6.667L13.333 0 20 0" transform="translate(1 1) rotate(-180 10 10) rotate(90 16.667 3.333)" />
        </g>
        <path d="M13.333 6.667L13.333 0 20 0" transform="translate(1 1) rotate(90 16.667 3.333)" />
      </g>
    </g>
  </svg>
);
export default Fullscreen;
