import Emoji_Bar_ThumbsUp from './images/static_svg/Emoji_Bar_ThumbsUp.svg';
import Emoji_Bar_Hearts from './images/static_svg/Emoji_Bar_Hearts.svg';
import Emoji_Bar_Smiley from './images/static_svg/Emoji_Bar_Smiley.svg';
import Emoji_Bar_Shocked from './images/static_svg/Emoji_Bar_Shocked.svg';
import Emoji_Bar_Happy from './images/static_svg/Emoji_Bar_Happy.svg';
import Emoji_Bar_Love from './images/static_svg/Emoji_Bar_Love.svg';
import Emoji_Bar_Yuuck from './images/static_svg/Emoji_Bar_Yuuck.svg';

export const emojiTypes = [
  {
    color: '#FF194B',
    emoji: Emoji_Bar_Hearts,
    name: 'Hearts',
    value: 'hearts',
    position: 280
  },
  {
    color: '#FF9664',
    emoji: Emoji_Bar_Happy,
    name: 'Happy',
    value: 'happy',
    position: 30
  },
  {
    color: '#FFD169',
    emoji: Emoji_Bar_Smiley,
    name: 'Smiley',
    value: 'smiley',
    position: 130
  },
  {
    color: '#5FCDA1',
    emoji: Emoji_Bar_Yuuck,
    name: 'Yuuck',
    value: 'yuuck',
    position: 230
  },
  {
    color: '#00C2FB',
    emoji: Emoji_Bar_Shocked,
    name: 'Shocked',
    value: 'shocked',
    position: 80
  },
  {
    color: '#5C3ADB',
    emoji: Emoji_Bar_ThumbsUp,
    name: 'ThumbsUp',
    value: 'thumbsUp',
    position: 330
  },
  {
    color: '#FF85DE',
    emoji: Emoji_Bar_Love,
    name: 'Love',
    value: 'love',
    position: 180
  }
];

export function getColor(index) {
  return emojiTypes[index].color;
}
