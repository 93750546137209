import React from 'react';
import { Link } from 'react-router-dom';
import Firebase from '../../firebase/setup';
import Logo from '../../images/logo_black.gif';

const Header = props => {
  const logOut = () => {
    Firebase.auth().signOut();
  };

  return (
    <header className="text-gray-600 body-font">
      <div className="container mx-auto flex flex-wrap p-5 md:flex-row justify-between items-center">
        <Link to="/" className="flex title-font font-medium items-center text-gray-900">
          <img className="pr-2" alt="The Verse" src={Logo} style={{ height: '30px' }} />
          <span className="text-xl">The Verse</span>
        </Link>
        {props.authed && !props.user.isAnonymous && (
          <nav className="md:mr-auto md:ml-4 md:py-1 md:pl-4 md:border-l md:border-gray-400 flex flex-wrap items-center text-base justify-center">
            <Link className="hover:text-gray-900 pr-4" to="/streams">
              Streams
            </Link>
            <span className="md:border-l md:border-gray-400 pl-4" style={{ fontSize: '10px' }}>
              {props.user ? props.user.email : null} <br />
              {props.user ? props.user.uid : null}
            </span>
          </nav>
        )}

        {props.authed && !props.user.isAnonymous ? (
          <button
            className="inline-flex items-center bg-gray-100 border-0 py-1 px-3 focus:outline-none hover:bg-gray-200 rounded text-base"
            onClick={logOut}
          >
            Log out
            <svg
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              className="w-4 h-4 ml-1"
              viewBox="0 0 24 24"
            >
              <path d="M5 12h14M12 5l7 7-7 7" />
            </svg>
          </button>
        ) : (
          <nav className="md:ml-auto flex flex-wrap items-center text-base justify-center">
            {/* <Link className="hover:text-gray-900" to="/login">
                            Log in
                        </Link> */}
          </nav>
        )}
      </div>
    </header>
  );
};

export default Header;
