import React from 'react';
import './guideforpartners.scss';
import { CodeBlock, dracula } from 'react-code-blocks';
import code1ex from './code_example_1.js';
import code2ex from './code_example_2.js';
import code3ExIncludeScript from './code_example_include_script';

const GuideForPartners = () => {
  const currentUrl = window.location.host;

  return (
    <div className="m-sm-8 m-8 container mx-auto guide-for-partners-font">
      <h1>Guide for partners</h1>

      <p className="my-md-2">
        A stream UID is needed in order for the plugin to connect to our platform as well as getting access to your
        dashboard. In most cases we will provide the stream UID.
      </p>

      <div className="ml-4 mt-6">
        <ul className="list-disc">
          <li>
            Dashboard can be accessed through this URL <b>{currentUrl}/dashboard/&lt;streamUID&gt;</b>
          </li>
        </ul>
      </div>

      <h1 className="mt-12">Implementing the library</h1>

      <p>Include The Verse Library in your index.html:</p>

      <div className="my-6">
        <CodeBlock text={code3ExIncludeScript} theme={dracula} />
      </div>

      <div className="mt-2">
        The Verse plugin will now be available. There are 2 properties to be set when initializing the plugin
      </div>
      <div className="ml-4 mt-2">
        <ul className="list-disc">
          <li>streamUid - the ID provided for each stream</li>
          <li>selector - the ID of the HTML element which is overlayed on top of your video-player</li>
        </ul>
      </div>

      <div className="my-6">
        Below are 2 examples of using The Verse Plugin. Steps taken to implement the plugin
        <ul className="list-disc ml-4 mt-2">
          <li>Include The Verse script in index.html file</li>
          <li>
            Define a variable "theVerseWidget"/"TheVerseEmojiBar" to hold a reference to the plugin by calling the
            function .new() including streamUid and selector
          </li>
          <li>When a reference is created then call the function .render() to render the plugin</li>
          <li>If possible, when page is unloading call .unmount()</li>
        </ul>
      </div>

      <h1 className="mt-12">HTML / Javascript Example</h1>
      <div className="my-6">
        <CodeBlock text={code1ex} theme={dracula} />
      </div>

      <h1 className="mt-12">ReactJS Component example</h1>
      <div className="my-6">
        <CodeBlock text={code2ex} theme={dracula} />
      </div>
    </div>
  );
};

export default GuideForPartners;
