import React, { useState } from 'react';

const Select = ({ onChange, name, value, label, options, className }) => {
  const [open, setOpen] = useState(false);
  const id = `select-for-${name}`;

  return (
    <div id={id} className={className}>
      <label htmlFor={name} className="leading-7 text-sm text-gray-600 capitalize">
        {label}
      </label>
      <div className="relative mb-4">
        <div className="flex flex-col items-center relative">
          <div className="w-full svelte-1l8159u">
            <div
              onClick={() => setOpen(!open)}
              onBlur={e => {
                if (!document.getElementById(id).contains(e.relatedTarget)) {
                  setOpen(false);
                }
              }}
              className="cursor-pointer mb-2 bg-white flex border border-gray-300 rounded svelte-1l8159u"
            >
              <div className="flex flex-auto flex-wrap"></div>
              <input
                value={value}
                readOnly
                className="cursor-pointer capitalize rounded p-1 leading-8 px-3 appearance-none outline-none w-full text-gray-800 svelte-1l8159u"
              />

              <div className="text-gray-300 w-8 py-1 pl-2 pr-1 border-l flex items-center border-gray-300 svelte-1l8159u">
                <div className="w-6 text-gray-600 outline-none focus:outline-none">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="100%"
                    height="100%"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-chevron-down w-4 h-4"
                  >
                    <polyline points="18 15 12 9 6 15"></polyline>
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`${
              open ? '' : 'hidden'
            } absolute shadow top-100 z-40 w-full lef-0 rounded max-h-select overflow-y-auto svelte-5uyqqj`}
          >
            <div className="flex flex-col w-full">
              {options.map((option, key) => (
                <button
                  key={key}
                  className="cursor-pointer w-full border-gray-100 rounded-t border-b hover:bg-teal-100"
                  type="button"
                  onClick={() => {
                    onChange(option);
                    setOpen(false);
                  }}
                >
                  <div className="flex w-full items-center p-2 pl-2 border-transparent bg-white border-l-2 relative hover:bg-teal-600 hover:text-teal-100 hover:border-teal-600">
                    <div className="w-full items-center flex">
                      <div className="mx-2 leading-6 capitalize">{option.name}</div>
                    </div>
                  </div>
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Select;
