import React from 'react';
import './IconButton.scss';
import Play from './icons/play';
import Pause from './icons/pause';
import Fullscreen from './icons/fullscreen';
import SoundOn from './icons/soundOn';
import SoundOff from './icons/soundOff';
import SoundHalf from './icons/soundHalf';

const IconSvg = ({ Icon }) => {
  if (Icon === 'play') {
    return Play;
  }
  if (Icon === 'pause') {
    return Pause;
  }
  if (Icon === 'fullscreen') {
    return Fullscreen;
  }
  if (Icon === 'soundOn') {
    return SoundOn;
  }
  if (Icon === 'soundOff') {
    return SoundOff;
  }
  if (Icon === 'soundHalf') {
    return SoundHalf;
  }

  return null;
};

const IconButton = React.memo(({ onClick, className, Icon, labelText }) => {
  return (
    <button aria-label={labelText} type="button" onClick={onClick} className={`icon-button ${className}`}>
      <IconSvg Icon={Icon} />
      {labelText && <p className="icon-label">{labelText}</p>}
    </button>
  );
});

export default IconButton;
