import React, { useEffect, useState } from 'react';
import Session from 'react-session-api';
import * as streamHelper from '../../firebase/streamHelper';
import { Redirect } from 'react-router-dom';
import './password.scss';
import Countdown from 'react-countdown';
import { isMobileOnly } from 'react-device-detect';

const Password = props => {
  const { docId } = props.location.state;
  const [stream, setStream] = useState(undefined);
  const [streamPassword, setStreamPassword] = useState('');
  const [correctPassword, setCorrectPassword] = useState(false);

  useEffect(() => {
    if (!docId) return;
    streamHelper.getStreamById(docId).then(doc => {
      setStream(doc.data());
    });
  }, [docId]);

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <span />;
    }
    return (
      <span>
        {!isMobileOnly ? 'Starts in' : ''} {days} d : {hours} h : {minutes} m : {seconds} s
      </span>
    );
  };

  const submitPassword = e => {
    e.preventDefault();

    if (streamPassword !== '') {
      if (streamPassword === stream.password) {
        Session.set(`docId_${docId}`, true);
        setCorrectPassword(true);
      }
    }
  };

  return !correctPassword ? (
    <section className="text-gray-600 body-font">
      <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
        <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
          <img
            className="object-cover object-center rounded"
            src={`${(stream && stream.image_url) || ''}`}
            alt="Stream identity"
          />
        </div>
        <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
          <h1 className="title-font sm:text-4xl text-3xl mb-2 font-medium text-gray-900">
            {(stream && stream.name) || ''}
          </h1>
          {stream && stream.isLiveStream && stream.date_start ? (
            <h2 className="title-font sm:text-1xl text-md mb-4 font-medium text-gray-700">
              <Countdown date={stream.date_start.toDate() || new Date()} renderer={renderer} />
            </h2>
          ) : null}
          {stream && stream.description ? <p className="mb-8 leading-relaxed">{stream.description || ''}</p> : null}
          <form onSubmit={submitPassword}>
            <div className="flex w-full md:justify-start justify-center items-end">
              <div className="relative mr-4 lg:w-full xl:w-1/2 w-2/4">
                <label htmlFor="hero-field" className="leading-7 text-sm text-gray-600">
                  Stream Password
                </label>
                <input
                  type="text"
                  autoComplete="off"
                  id="password"
                  name="password"
                  value={streamPassword}
                  onChange={e => setStreamPassword(e.target.value)}
                  className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:ring-2 focus:ring-indigo-200 focus:bg-transparent focus:border-indigo-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                />
              </div>
              <button className="inline-flex text-white bg-indigo-500 border-0 py-2 mb-1 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  ) : (
    <Redirect
      to={{
        pathname: `/stream/${docId}`
      }}
    />
  );
};

export default Password;
