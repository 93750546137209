import React from 'react';
import './ProgressBar.scss';
import 'rc-slider/assets/index.css';
import Slider from 'rc-slider';
import Duration from '../duration';
import IconButton from '../icon-button';

const ProgressBar = React.memo(
  ({ played, onSliderChange, onBeforeChange, onAfterChange, duration, playing, togglePlaystate }) => {
    return (
      <div className="progress-bar">
        {!playing ? (
          <IconButton height={40} width={40} onClick={togglePlaystate} Icon="play" className="play" />
        ) : (
          <IconButton height={40} width={40} onClick={togglePlaystate} Icon="pause" className="pause" />
        )}
        <Slider
          value={played * 100}
          onChange={onSliderChange}
          onBeforeChange={onBeforeChange}
          onAfterChange={onAfterChange}
        />
        <div className="duration">
          -
          <Duration seconds={duration + duration * -played} />
        </div>
      </div>
    );
  }
);

export default ProgressBar;
