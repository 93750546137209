import code3ExIncludeScript from './code_example_include_script';

const code1ex =
  '<html lang="en">\n' +
  '<head></head>\n' +
  '<body>\n' +
  '<div style="width: 100%; height: 100%;">\n' +
  '    <div style="position: absolute; width: 100%; height: 100%">\n' +
  '        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/<YOUR_STREAM_HERE>" frameborder="0"\n' +
  '                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"\n' +
  '                allowfullscreen>\n' +
  '        </iframe>\n' +
  '    </div>\n' +
  '    <div id="theverse-emoji-plugin-wrapper" style="position: absolute; width: 100%; height: 100%;"></div>\n' +
  '</div>\n' +
  '\n' +
  code3ExIncludeScript +
  '<script>\n' +
  '    const theVerseWidget = TheVerseLib.default.widgets.emojiBar.new({\n' +
  '        selector: "#theverse-emoji-plugin-wrapper",\n' +
  '        streamUid: "<streamUid>",\n' +
  '        align: "bottom-right", // Optional "bottom-right", "center-right". Default: "bottom-right"\n' +
  '    });\n' +
  '\n' +
  '    theVerseWidget.render();\n' +
  '\n' +
  '     // Call when emoji bar should be removed \n' +
  '     // theVerseWidget.unmount();\n' +
  '</script>\n' +
  '</body>\n' +
  '</html>';
export default code1ex;
