import React, { useState } from 'react';

const EmbedPageDemo = () => {
  const [embedCode, setEmbedCode] = useState(undefined);

  return (
    <div className="embedPage container px-5 mx-auto">
      <div className="relative mb-4">
        <label htmlFor="name" className="leading-7 text-sm text-gray-600">
          Paste embed code here
        </label>
        <input
          type="text"
          onBlur={e => setEmbedCode(e.target.value)}
          className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
        />
      </div>
      <div style={{ overflow: 'hidden' }} dangerouslySetInnerHTML={{ __html: embedCode }} />
    </div>
  );
};

export default EmbedPageDemo;
