import React from 'react';

const Error = ({ message }) => {
  if (!message) return null;

  return (
    <div className="w-full bg-red-100 bg-opacity-50 rounded border border-red-300 text-xs outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out">
      {message}
    </div>
  );
};

export default Error;
