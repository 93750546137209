import React, { useState, useEffect } from 'react';
import * as streamHelper from '../../../../firebase/streamHelper';
import { useParams, useHistory } from 'react-router-dom';
import { createVideoUrl, emojiBackgrounds, providers, getUrlId } from '../../../Stream/streamUtils';
import Select from '../../../../components/Select';
import Input from '../../../../components/Input';
import Error from '../../../../components/Error';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { isDate, toDate } from 'date-fns';

const origSelectValue = { name: '', value: '' };

function CreateEditStream({ user }) {
  const history = useHistory();

  const [streamName, setStreamName] = useState('');
  const [streamDescription, setStreamDescription] = useState('');
  const [sourceValue, setSourceValue] = useState(origSelectValue);
  const [password, setPassword] = useState(false);
  const [streamPassword, setStreamPassword] = useState('');
  const [isLiveStream, setIsLiveStream] = useState(false);
  const [showEmojisOnPlayback, setShowEmojisOnPlayback] = useState(true);
  const [videoUrl, setVideoUrl] = useState('');
  const [dashboardUrl, setDashboardUrl] = useState('');
  const [embedUrl, setEmbedUrl] = useState(undefined);
  const [disabled, setDisabled] = useState(false);
  const [streamStartDateTime, setStreamStartDateTime] = useState(new Date());
  const [streamImage, setStreamImage] = useState(undefined);
  const [emojiBarClass, setEmojiBarClass] = useState(origSelectValue);
  const [error, setError] = useState(null);

  const { id } = useParams();
  const editMode = id != null;

  function handleStreamStartDateTimeChanged(dateSelected) {
    if (dateSelected && isDate(dateSelected)) {
      const newDate = toDate(dateSelected);
      setStreamStartDateTime(newDate);
    } else {
      setStreamStartDateTime('');
    }
  }

  function handleStreamImageChange(e) {
    if (e.target.files[0]) {
      let image = e.target.files[0];
      setStreamImage(image);
      console.log(image);
    }
  }

  useEffect(() => {
    if (!id) return;
    streamHelper.getStreamById(id).then(doc => {
      let stream = doc.data();
      if (stream == null || stream.user_id !== user.user_id) {
        history.push('/streams');
      } else {
        setStreamName(stream.name);
        setStreamDescription(stream.description);
        setVideoUrl(`${createVideoUrl(stream.provider, stream.url_id)}`);
        setEmbedUrl(
          `<iframe src="${window.location.origin}/stream/${id}" height="600" width="800" title="Iframe Example" style="border: 0"></iframe>`
        );
        setIsLiveStream(stream.isLiveStream);
        setShowEmojisOnPlayback(stream.showEmojisOnPlayback ?? true);
        setPassword(stream.password ?? true);
        setStreamPassword(stream.password ?? '');
        const sourceValue = providers.filter(provider => provider.value === stream.provider);
        setSourceValue(sourceValue[0]);
        setDashboardUrl(`${window.location.origin}/dashboard/${id}`);
        setDisabled(stream.disabled);
        handleStreamStartDateTimeChanged(stream.date_start ? stream.date_start.toDate() : null);
        const barClasses = emojiBackgrounds.filter(barClass => barClass.value === stream.emojiBarClass);
        setEmojiBarClass(barClasses[0]);
      }
    });
  }, [history, id, user.user_id]);

  const createUpdateStream = () => {
    const urlId = getUrlId(sourceValue.value, videoUrl);
    if (!urlId) {
      setError('Not a valid url');

      return;
    } else {
      console.log(streamStartDateTime);
      const data = {
        name: streamName,
        description: streamDescription ?? '',
        provider: sourceValue.value,
        url_id: urlId,
        password: password ? streamPassword : false,
        isLiveStream,
        showEmojisOnPlayback,
        user_uid: user.uid,
        date_created: Date.now(),
        emojiBarClass: emojiBarClass.value
      };
      if (streamStartDateTime) {
        data.date_start = streamStartDateTime;
      }
      if (editMode) {
        streamHelper.updateStream(id, data).then(() => {
          if (streamImage != null) {
            streamHelper.uploadImage(id, streamImage);
          }
          history.push('/streams');
        });
      } else {
        streamHelper.addStream(data).then(doc => {
          if (streamImage != null) {
            // We use the newly created stream doc.id to upload image and update the stream doc
            streamHelper.uploadImage(doc.id, streamImage);
          }

          // setPassword(false);
          // setIsLiveStream(false);
          // setShowEmojisOnPlayback(true);
          // setStreamName("");
          // setStreamDescription("");
          // setStreamPassword("");
          // setSourceValue(origSourceValue);
          // setVideoUrl("");
          setEmbedUrl(
            `<iframe src="${window.location.origin}/stream/${doc.id}" height="600" width="800" title="Iframe Example" ></iframe>`
          );
          setDashboardUrl(`${window.location.origin}/dashboard/${doc.id}`);
          setStreamStartDateTime(null);
          // setStreamImage(undefined);
        });
      }
    }
  };

  return (
    <section>
      <div className="container px-5 mx-auto flex my-5">
        <div className="bg-gray-100 rounded-lg p-8 flex flex-col md:ml-auto w-full relative">
          <div className="full-width flex flex-wrap mb-4">
            <div className="sm:w-1 md:w-1/2">
              <div className="relative leading-8 inline-flex text-lg">Create Stream</div>
            </div>
            <div className="sm:w-1 md:w-1/2">
              <div className="relative leading-8 inline-flex">
                <label className="text-white bg-indigo-500 border-0 py-1 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg">
                  <input type="file" hidden onChange={handleStreamImageChange} accept="image/*" />
                  Stream Image
                </label>
              </div>
            </div>
          </div>
          <Input name="name" label="Name" onChange={setStreamName} value={streamName} />

          <div className="relative mb-3">
            <label htmlFor="streamDescription" className="leading-7 text-sm text-gray-600">
              Description
            </label>
            <textarea
              rows="3"
              value={streamDescription}
              onChange={e => setStreamDescription(e.target.value)}
              name="streamDescription"
              id="streamDescription"
              className="w-full leading-8 bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 transition-colors duration-200 ease-in-out"
            >
              {' '}
            </textarea>
          </div>

          {isLiveStream && (
            <div className="relative mb-6 mt-3 h-16">
              <label htmlFor="streamStartDateTime" className="leading-7 text-sm text-gray-600">
                Stream start date
              </label>
              <div className="w-full leading-8 mb-3">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDateTimePicker
                    variant="inline"
                    ampm={false}
                    value={streamStartDateTime}
                    onChange={handleStreamStartDateTimeChanged}
                    onError={console.log}
                    format="yyyy/MM/dd HH:mm"
                  />
                </MuiPickersUtilsProvider>
              </div>
            </div>
          )}

          <div className="flex flex-wrap -m-4">
            <div className="w-full md:w-1/2 p-4">
              <Input name="url" label="Url" onChange={setVideoUrl} value={videoUrl} />
            </div>
            <div className="w-full md:w-1/2 p-4">
              <Select
                name="source"
                onChange={setSourceValue}
                options={providers}
                label="Source"
                value={sourceValue.name}
              />
            </div>
          </div>
          <div className="flex flex-wrap -m-4 mb-4">
            <div className="md:w-1/2 p-4">
              <div className="relative">
                <label className="inline-flex items-center mt-3">
                  <input
                    type="checkbox"
                    className="form-checkbox h-5 w-5 text-gray-600"
                    checked={password}
                    onChange={event => setPassword(event.target.checked)}
                  />
                  <span className="ml-2 text-gray-700">Set password</span>
                </label>

                <label className="flex items-center mt-3">
                  <input
                    type="checkbox"
                    className="form-checkbox h-5 w-5 text-gray-600"
                    checked={isLiveStream}
                    onChange={event => setIsLiveStream(event.target.checked)}
                  />
                  <span className="ml-2 text-gray-700">Is live stream</span>
                </label>

                <label className="flex items-center mt-3">
                  <input
                    type="checkbox"
                    className="form-checkbox h-5 w-5 text-gray-600"
                    checked={showEmojisOnPlayback}
                    onChange={event => setShowEmojisOnPlayback(event.target.checked)}
                  />
                  <span className="ml-2 text-gray-700">Show emojis on playback</span>
                </label>
              </div>
            </div>
            <div class="md:w-1/2 p-4">
              <Input
                name="streamPassword"
                label="Stream password"
                onChange={setStreamPassword}
                value={streamPassword}
              />
            </div>
          </div>
          <div className="relative">
            <Select
              name="emojiBarClass"
              label="Emoji background color"
              onChange={setEmojiBarClass}
              value={emojiBarClass.name}
              options={emojiBackgrounds}
            />
          </div>

          <Error message={error} />

          <button
            disabled={disabled}
            onClick={createUpdateStream}
            className="flex mx-auto mt-16 text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg"
          >
            {editMode ? 'Update' : 'Create'} stream
          </button>
          <div className="relative mb-4 mt-3 md:mt-0">
            <label htmlFor="embed" className="leading-7 text-sm text-gray-600">
              Embed
            </label>
            <textarea
              readOnly
              id="embed"
              name="embed"
              className="cursor-not-allowed w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
              defaultValue={embedUrl}
            ></textarea>
          </div>
          <Input
            name="dashboardUrl"
            label="Dashboard url"
            onChange={setStreamPassword}
            value={dashboardUrl}
            readOnly
            className="mb-4"
          />
        </div>
      </div>
    </section>
  );
}

export default CreateEditStream;
